import { render, staticRenderFns } from "./StudentForm.vue?vue&type=template&id=84acc6da&scoped=true&"
import script from "./StudentForm.vue?vue&type=script&lang=js&"
export * from "./StudentForm.vue?vue&type=script&lang=js&"
import style0 from "./StudentForm.vue?vue&type=style&index=0&id=84acc6da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84acc6da",
  null
  
)

export default component.exports